<template>
	<div id="school">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>管理</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">学校设置</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content u-f">
				<div class="school-info u-f4">
					<div class="u-f-item u-f-jsb info-title">
						<div>学校信息</div>
						<el-button type="primary" size="small" style="width: 100px;" @click="editVisible = true">编辑</el-button>
					</div>
					<div class="infos">
						<div class="u-f">
							<el-avatar shape="square" :size="60" :src="imageUrl"></el-avatar>
							<div style="margin-left: 10px;">
								<div class="school-name">{{ info.school_name }}</div>
								<div class="school-type">{{ info.school_nature == '1' ? '独立学校' : '集团化学校' }}</div>
							</div>
						</div>
						<div class="school-brief">
							<span>简介</span>
							{{ info.school_intro }}
						</div>
					</div>
				</div>
				<div class="branch-school u-f9">
					<div class="u-f-item u-f-jsb branch-school-title">
						<div>分校区</div>
						<el-button size="small" style="width: 100px;" @click="addVisible = true">添加校区</el-button>
					</div>
					<el-table :data="schools" style="width: 100%">
						<el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
						<el-table-column prop="name" label="校区名称" width="180" align="center"></el-table-column>
						<el-table-column prop="address" label="校区地址"></el-table-column>
						<el-table-column prop="admin_nickname" label="教务管理员" width="150" align="center"></el-table-column>
						<el-table-column prop="contact_phone" label="联系电话" width="150" align="center"></el-table-column>
						<el-table-column prop="contact_email" label="电子邮件" width="150" align="center"></el-table-column>
						<el-table-column label="操作" width="150" align="center">
							<template slot-scope="scope">
								<el-button @click="editShow(scope.row)" type="text" size="small">编辑</el-button>
								<el-button @click="delCampus(scope.row.id)" type="text" size="small" style="color: red;">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>

			<!-- 编辑 -->
			<el-dialog title="编辑" :visible.sync="editVisible" width="40%" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							校名
						</div>
						<el-input v-model="schoolName" placeholder="请输入校区名称" style="width: 75%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							性质
						</div>
						<el-radio-group v-model="school_nature">
							<el-radio-button label="1">独立学校</el-radio-button>
							<el-radio-button label="2">集团化学校</el-radio-button>
						</el-radio-group>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">
							<span>*</span>
							简介
						</div>
						<el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="school_intro" style="width: 75%;"></el-input>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">
							<span>*</span>
							校徽
						</div>
						<el-upload
							class="avatar-uploader"
							action="http://school.blzzxx.cn/v1/school_api/common/upload"
							:show-file-list="false"
							:on-success="handleAvatarSuccess"
							:before-upload="beforeAvatarUpload"
							:headers="headers"
						>
							<img v-if="imageUrl" :src="imageUrl" class="avatar" />
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="editVisible = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="edit_school">确 定</el-button>
				</span>
			</el-dialog>

      <!-- 选择联系人 -->
      <v-teacher-select v-model="selectShow" @select="onTeacherSelect" />

			<!-- 添加校区 -->
			<el-dialog title="添加校区" :visible.sync="addVisible" width="480px" :before-close="handleClose">
				<!--选择 -->
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							校区名称
						</div>
						<el-input v-model="school_name" placeholder="请输入校区名称" style="width: 75%;"></el-input>
					</div>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">教务管理员</div>
						<div class="u-f-item input-item" style="width: 75%;">
              <el-input :value="teacherSelectText" readonly style="width: 90%" />
							<el-button style="margin-left: 10px;" @click="selectShow = true">选择</el-button>
						</div>
					</div>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">校区地址</div>
						<el-input v-model="address" placeholder="请输入校区地址" style="width: 75%;"></el-input>
					</div>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">联系电话</div>
						<el-input v-model="contact_phone" placeholder="请输入联系电话" style="width: 75%;"></el-input>
					</div>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">电子邮件</div>
						<el-input v-model="contact_email" placeholder="请输入电子邮件" style="width: 75%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeShow" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="addCampus">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 编辑校区 -->
			<el-dialog title="编辑校区" :visible.sync="editCampusShow" width="480px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							名称
						</div>
						<el-input v-model="edit_school_name" placeholder="请输入名称" style="width: 75%;"></el-input>
					</div>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">教务管理员</div>
						<div class="u-f-item input-item" style="width: 75%;">
              <el-input :value="teacherSelectText" readonly style="width: 90%" />
							<el-button style="margin-left: 10px;" @click="selectShow = true">选择</el-button>
						</div>
					</div>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">校区地址</div>
						<el-input v-model="address" placeholder="请输入校区地址" style="width: 75%;"></el-input>
					</div>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">联系电话</div>
						<el-input v-model="contact_phone" placeholder="请输入联系电话" style="width: 75%;"></el-input>
					</div>
				</div>
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">电子邮件</div>
						<el-input v-model="contact_email" placeholder="请输入电子邮件" style="width: 75%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeShow" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="editCampus">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
import base from '../../../api/base.js';
import VTeacherSelect from "@/components/teacher-select";
export default {
	components: {
    VTeacherSelect,
		index
	},
	data() {
		return {
			editCampusShow: false,
			editVisible: false,
			addVisible: false,
			school_nature: '1',
			school_intro: '',
			imageUrl: '',
			image: '',
			schoolName: '',
			schools: [],
			school_name: '',
			info: '',
			action: '/common/upload',
			headers: {
				token: window.localStorage.getItem('token')
			},
			edit_school_name: '',
			CampusId: '',
			teachers: '',
			selectShow: false,
			selectList: [],
			teacher_list: [],
			key: '',
			typeIndex: 0,
			dataIndex: 0,
			address: '',
			contact_phone: '',
			contact_email: ''
		};
	},
  computed: {
    teacherSelectText() {
      return this.selectList.map(item => item.username).join(',')
    }
  },
  watch: {
    selectShow(val) {
      if (val) {
        console.log('l', val)
      }
    }
  },
	mounted() {
		this.$dragging.$on('dragged', ({ value }) => {
			console.log(value.item);
			console.log(value.list);
		});
		this.teacherList();
		this.getSchoolInfo();
		this.schoolCampusIndex();
	},
	methods: {
		closeShow(){
			this.init()
			this.addVisible = false;
			this.editCampusShow = false;
		},
		init(){
			this.CampusId = '';
			this.edit_school_name = '';
			this.address = '';
			this.contact_phone = '';
			this.contact_emai = '';
			this.teachers = '';
			this.typeIndex = 0;
			this.dataIndex = 0;
		},
    /**
     * 老师选择回调
     */
    onTeacherSelect(res, list) {
      this.selectList = list
    },
		handleAvatarSuccess(res, file) {
			if (res.code === 1) {
				this.imageUrl = res.data.fullurl;
				this.image = res.data.url;
			} else {
				this.$message.error(res.msg);
			}
		},
		beforeAvatarUpload(file) {
			console.log(file);
		},
		handleClose(done) {
			this.init()
			done();
		},
		addSchools() {
			let arr = {
				schoolName: ''
			};
			this.schools = this.schools.concat(arr);
		},
		delSchool(i) {
			this.schools.splice(i, 1);
		},
		// 编辑弹窗
		editShow(item) {
			this.CampusId = item.id;
			this.edit_school_name = item.name;
			this.address = item.address;
			this.contact_phone = item.contact_phone;
			this.contact_email = item.contact_email;
			this.teachers = item.user_id;
      this.selectList = [
        {
          id: item.user_id,
          username: item.admin_nickname,
        }
      ]
			this.editCampusShow = true;
		},
		// 获取学校信息
		getSchoolInfo() {
			this.$api.setting.schoolInfo().then(res => {
				if (res.data.code == 1) {
					let data = res.data.data;
					this.info = data;
					this.school_nature = data.school_nature;
					this.school_intro = data.school_intro;
					this.imageUrl = base.imgUrl + data.school_badge;
					this.image = data.school_badge;
					this.schoolName = data.school_name;
				}
			});
		},
		// 修改学校信息
		edit_school() {
			let data = {
				school_name: this.schoolName,
				school_nature: this.school_nature,
				school_intro: this.school_intro,
				school_badge: this.image
			};
			if (this.schoolName == '' || !this.schoolName) {
				return this.$message.error('请输入学校名称');
			}
			if (this.school_intro == '' || !this.school_intro) {
				return this.$message.error('请输入学校简介');
			}
			if (this.image == '' || !this.image) {
				return this.$message.error('请上传学校校徽');
			}
			this.$api.setting.editSchool(data).then(res => {
				if (res.data.code == 1) {
					this.editVisible = false;
					this.getSchoolInfo();
					this.$message.success('修改成功');
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 校区列表
		schoolCampusIndex() {
			this.$api.setting.schoolCampusIndex().then(res => {
				if (res.data.code == 1) {
					this.schools = res.data.data.rows;
				}
			});
		},
		// 添加校区
		addCampus() {
			if (this.school_name == '' || !this.school_name) {
				return this.$message.error('请输入校区名称');
			}
			this.$api.setting
				.addCampus({
					name: this.school_name,
					user_id: this.teachers,
					address: this.address,
					contact_phone: this.contact_phone,
					contact_email: this.contact_email
				})
				.then(res => {
					if (res.data.code == 1) {
						this.addVisible = false;
						this.init()
						this.schoolCampusIndex();
						this.$message.success('添加成功');
					} else {
						this.$message.error(res.msg);
					}
				});
		},
		// 编辑校区
		editCampus() {
			if (this.edit_school_name == '' || !this.edit_school_name) {
				return this.$message.error('请输入校区名称');
			}
			this.$api.setting
				.editCampus({
					id: this.CampusId,
					name: this.edit_school_name,
					user_id: this.teachers,
					address: this.address,
					contact_phone: this.contact_phone,
					contact_email: this.contact_email
				})
				.then(res => {
					if (res.data.code == 1) {
						this.editCampusShow = false;
						this.init()
						this.schoolCampusIndex();
						this.$message.success('编辑成功');
					} else {
						this.$message.error(res.msg);
					}
				});
		},
		// 删除校区
		delCampus(id) {
			this.$confirm('确定要删除？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				this.$api.setting
					.delCampus({
						id: id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							this.schoolCampusIndex();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			});
		},
		// 教师列表
		teacherList() {
		},
		// 重置
		reset() {
			this.teachers = '';
			this.teacherList();
		},
		teachTap(item) {
			this.teachers = item.teacher_id;
		},
		unique(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr) && res.set(arr, 1));
		},
		unique2(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr.teacher_id) && res.set(arr.teacher_id, 1));
		},
		// 切换分类
		typeTap(index) {
			this.typeIndex = index;
			this.dataIndex = 0;
		},
		dataTap(index) {
			this.dataIndex = index;
		}
	}
};
</script>

<style lang="scss">
#school {
	.content {
		background-color: #ffffff;
		height: 85vh;
		margin-top: 15px;
		.school-info {
			border-right: 1px solid #f0f2f5;
			.info-title {
				background-color: #e4edff;
				padding: 15px 40px;
			}
			.infos {
				padding: 30px 40px;
				.school-name {
					font-weight: bold;
					font-size: 0.8333vw;
				}
				.school-type {
					font-size: 0.625vw;
					background-color: #e4f1ff;
					color: #2d8cf0;
					display: inline-block;
					padding: 3px 10px;
					margin-top: 5px;
				}
				.school-brief {
					background-color: #f2f4f8;
					padding: 15px 20px;
					margin-top: 30px;
					span {
						color: #a4a4a4;
						margin-right: 15px;
					}
				}
			}
		}
		.branch-school {
			.branch-school-title {
				background-color: #e4edff;
				padding: 15px 40px;
			}
			.branch-school-list {
				padding: 30px 40px;
				display: flex;
				flex-wrap: wrap;
				.branch-school-item {
					border: 1px solid #eeeeee;
					background-color: #ffffff;
					width: 30%;
					margin-right: 20px;
					margin-bottom: 20px;
					border-radius: 3px;
					.cont {
						padding: 15px 30px;
						.section {
							font-size: 0.625vw;
							background-color: #e4f1ff;
							color: #2d8cf0;
							display: inline-block;
							padding: 3px 10px;
						}
						.el-icon-s-flag {
							color: #2d8cf0;
							margin-right: 3px;
						}
					}
					.meun {
						padding: 10px 0;
						background-color: #fafafa;
						color: #8d9da6;
						div {
							border-right: 1px solid #e8eaec;
							cursor: pointer;
							i {
								margin-right: 3px;
							}
						}
						div:last-child {
							border-right: none;
						}
						.meun-item:hover {
							color: #ed6335;
						}
					}
				}
				div:nth-child(3n) {
					margin-right: 0;
				}
			}
		}
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
.el_option {
	display: none !important;
}
</style>
